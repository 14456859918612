import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const SectionWrapper = styled.div`
  margin-top: 16rem;
  width: 100%;
  padding: 4rem 2rem;
  background: #040A19;
  color: white;
`;

const GridContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  position: relative;
  padding: 2rem;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(72, 171, 219, 0.1);
    transform: translateY(-5px);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
  }
`;

const Title = styled.h3`
  color: #48ABDB;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  min-height: 3em;
  display: flex;
  align-items: center;
`;

const Description = styled.p`
  color: white;
  font-size: 1.1rem;
  line-height: 1.6;
  opacity: 0.9;
`;

const FeaturesSection = () => {
  const intl = useIntl();

  const features = [
    {
      titleId: "features.symbiosis.title",
      descriptionId: "features.symbiosis.description"
    },
    {
      titleId: "features.ai.title",
      descriptionId: "features.ai.description"
    },
    {
      titleId: "features.prevention.title",
      descriptionId: "features.prevention.description"
    },
    {
      titleId: "features.guide.title",
      descriptionId: "features.guide.description"
    },
    {
      titleId: "features.guided.title",
      descriptionId: "features.guided.description"
    },
    {
      titleId: "features.transparency.title",
      descriptionId: "features.transparency.description"
    }
  ];

  return (
    <SectionWrapper>
      <GridContainer>
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            <Title>{intl.formatMessage({ id: feature.titleId })}</Title>
            <Description>{intl.formatMessage({ id: feature.descriptionId })}</Description>
          </FeatureCard>
        ))}
      </GridContainer>
    </SectionWrapper>
  );
};

export default FeaturesSection;