import React from 'react';
import styled from 'styled-components';
import { useLanguage } from '../contexts/LanguageContext';

const LanguageButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.isActive ? '#47ABDB' : 'white'};
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: bold;
  padding: 0.5rem;
  
  &:hover {
    color: #47ABDB;
  }
`;

const LanguageWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Separator = styled.span`
  color: white;
  font-weight: bold;
`;

const LanguagePicker = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <LanguageWrapper>
      <LanguageButton 
        isActive={language === 'de'} 
        onClick={() => setLanguage('de')}
      >
        DE
      </LanguageButton>
      <Separator>|</Separator>
      <LanguageButton 
        isActive={language === 'en'} 
        onClick={() => setLanguage('en')}
      >
        EN
      </LanguageButton>
    </LanguageWrapper>
  );
};

export default LanguagePicker;