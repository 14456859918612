import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 250vh;
  overflow: hidden;
  margin-top: 16rem;
  margin-bottom: 16rem;
  background: black;

  @media (max-width: 768px) {
    height: 400vh;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
`;

const ContentOverlay = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

const ContentContainer = styled.div`
  width: 80%;
  padding: 4rem 0;

  @media (max-width: 768px) {
    width: 90%;
    padding: 2rem 0;
  }
`;

const MainHeadline = styled.h2`
  color: #49abdb;
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 4rem;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const PoeticSection = styled.div`
  color: #d69de6;
  font-size: 1.8rem;
  margin-bottom: 5rem;
  font-style: italic;

  p {
    margin-bottom: 1rem;
  }
`;

const CommunicationSpan = styled.span`
  font-style: normal;
  font-weight: bold;
`;

const ScienceCaresSection = styled.div`
  color: #d69de6;
  font-size: 1.8rem;
  margin-bottom: 0rem;
`;

const ScienceCares = styled.p`
  color: white;
  font-weight: bold;
`;

const MissionStatementWrapper = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 3rem 0;
  margin-top: 20rem;
  margin-bottom: 20rem;
`;

const MissionStatementContent = styled.div`
  width: 80%;
  margin: 0 auto;
  color: white;
  font-size: 2.4rem;
  line-height: 1.6;

  p {
    margin-bottom: 2rem;
  }

  @media (max-width: 1200px) {
    font-size: 1.8rem;
  }

  @media (max-width: 768px) {
    width: 90%;
    font-size: 1.6rem;
  }
`;

const PinkSpan = styled.span`
  color: #d69de6;
`;

const CommunicationSection = () => {
  return (
    <SectionWrapper>
      <VideoBackground 
        autoPlay 
        muted 
        loop 
        playsInline
      >
        <source src="/videos/vertical.mp4" type="video/mp4" />
      </VideoBackground>

      <ContentOverlay>
        <ContentContainer>
          <MainHeadline>
            THERE IS NO COMPARABLE POWER<br/>
            BESIDES COMMUNICATION
          </MainHeadline>

          <PoeticSection>
            <p>It reveals what we have in common to unify.</p>
            <p>Through the mind, emerging as thoughts and words,</p>
            <p>the soul is divided to reconnect.</p>
            <p>True connection is <CommunicationSpan>COMMUNICATION</CommunicationSpan>.</p>
          </PoeticSection>

          <ScienceCaresSection>
            <p>Because,</p>
            <ScienceCares>SCIENCE CARES</ScienceCares>
          </ScienceCaresSection>
        </ContentContainer>

        <MissionStatementWrapper>
          <MissionStatementContent>
            <p>
              We create unique concepts and transformative experiences. This is what we call our 
              mission: COMMUNICATION. We serve as a bridge connecting science, art, business, 
              politics, media, and the people.
              We are the gateway where all these different worlds meet, creative minds align, and 
              experts share their knowledge. Through our tireless commitment to promoting science, 
              we realize distinguished projects contributing to the common good.
              There is no comparable power besides communication. It reveals what we have in common 
              to unify. Through the mind, emerging as thoughts and words, the soul is divided to reconnect.
              <PinkSpan> True connection is COMMUNICATION.</PinkSpan>
            </p>
            <p>
              Because,<br/>
              <strong>SCIENCE CARES</strong>
            </p>
          </MissionStatementContent>
        </MissionStatementWrapper>
      </ContentOverlay>
    </SectionWrapper>
  );
};

export default CommunicationSection;