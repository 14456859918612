import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useLanguage } from '../contexts/LanguageContext';

const MainWrapper = styled.div`
  background: transparent;
  color: white;
`;

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  @media (max-width: 768px) {
    height: auto;
    min-height: 40vh;
  }
`;

const HeroContent = styled.div`
  position: absolute;
  top: 10%;
  left: 50px;
  z-index: 2;
  @media (max-width: 768px) {
    position: relative;
    max-width: 100%;
    top: auto;
    left: auto;
    transform: none;
    padding: 20px;
  }
`;

const HeroMedia = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  @media (max-width: 768px) {
    position: relative;
    height: 40vh;
  }
`;

const MediaItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${props => props.active ? 1 : 0};
  transform: translateX(${props => props.position}%);
  transition: opacity 0.0s ease-in-out, transform 1.5s ease-in-out;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;



const SubElementsSection = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const SubElement = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 300px;
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};
  background: #040A19;
  padding: 8px;
  ${props => props.isConference && `
    outline: 10px solid #90C4D9;
    outline-offset: -2px;
  `}
`;

const SubElementImageWrapper = styled.div`
  width: 100%;
  height: 70%;
  overflow: hidden;
`;

const SubElementImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${SubElement}:hover & {
    transform: scale(1.1);
  }
`;

const SubElementContent = styled.div`
  padding: 10px 2px;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ComingSoonBanner = styled.div`
  position: absolute;
  top: 30px;
  right: -50px;
  background-color: #47ABDB;
  color: white;
  padding: 5px 0;
  width: 200px;
  text-align: center;
  transform: rotate(45deg);
  font-size: 0.6em;
  font-weight: bold;
  z-index: 2;
  display: ${props => props.show ? 'block' : 'none'};
`;

const LinkTitle = styled.span`
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
`;

const LinkDescription = styled.span`
  color: white;
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BigHeadline = styled.h1`
  font-size: 5.5rem;
  margin-bottom: 1.5rem;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ColoredWord = styled.span`
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
  color: #48ABDB;
`;

const SubHeadline = styled.p`
  font-size: 1.4rem;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
  color: white;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ScienceCares = styled.h2`
  color: #48ABDB;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
  margin-bottom: 1rem;
`;

const ScienceCaresFirstWord = styled.span`
  font-size: 1.4rem;
  display: block;
  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const ScienceCaresRest = styled.span`
  font-size: 3rem;
  display: block;
  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const LogoOverlay = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 30%;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 170%;
    height: 170%;
    background: radial-gradient(circle, rgba(255,0,0,0.3) 0%, rgba(0,0,0,0) 70%);
    z-index: -1;
  }
  
  img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media (max-width: 768px) {
    width: 50%;
    top: 55%;
  }
`;

const WelcomeSection = styled.section`
  width: 100%;
  background: white;
  padding: 1.5rem 0;
`;

const WelcomeContent = styled.div`
  padding-left: 50px; // Match HeroContent's left position
  text-align: left;
  
  @media (max-width: 768px) {
    padding: 0 20px; // Match HeroContent's mobile padding
  }
`;

const WelcomeSmall = styled.div`
  color: #48ABDB;
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
`;

const WelcomeLarge = styled.div`
  color: #48ABDB;
  font-size: 5.5rem;
  font-weight: bold;
  line-height: 1.1;
  
  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const SlideShowContent = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRefs = useRef([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { language } = useLanguage();

  const slideShowItems = [
    {
      id: 1,
      mediaType: 'VIDEO',
      mediaUrl: '/videos/background.mp4'
    }
  ];

  useEffect(() => {
    // Log when the video loads or errors
    const videoElement = videoRefs.current[0];
    if (videoElement) {
      videoElement.addEventListener('loadeddata', () => {
        console.log('Video loaded successfully');
      });
      
      videoElement.addEventListener('error', (e) => {
        console.error('Error loading video:', videoElement.error);
      });
    }
  }, []);

  const subElements = [
    {
      title: "HEARTPORT PUBLIC",
      description: "Explore science, guided by doctors & AI, to optimize health & maximize life!",
      url: "/images/heartport_public.jpg",
      externalUrl: "https://public.heartport.org/",
      showComingSoon: false
    },
    {
      title: "HEARTPORT EXPERT",
      description: "Get the Latest Research Tailored to Your Specialty!",
      url: "/images/heartport_expert.jpg",
      externalUrl: "https://expert.heartport.org/",
      showComingSoon: false
    },
    {
      title: "HEARTS CONFERENCE",
      description: "Register Now!",
      url: "/images/hearts_conference.png",
      externalUrl: "https://conference.heartport.org/",
      showComingSoon: false,
    }
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (slideShowItems.length > 1) {
      const interval = setInterval(() => {
        setIsTransitioning(true);
        setTimeout(() => {
          setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slideShowItems.length);
          setIsTransitioning(false);
        }, 1500);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [slideShowItems.length]);

  const getSlidePosition = (index) => {
    if (index === currentSlideIndex) return isTransitioning ? -100 : 0;
    const nextIndex = (currentSlideIndex + 1) % slideShowItems.length;
    if (index === nextIndex) return isTransitioning ? 0 : 100;
    return 100;
  };

  const handleSubElementClick = (element) => {
    if (element.externalUrl && !element.showComingSoon) {
      window.open(element.externalUrl, '_blank');
    }
  };


  return (
    <MainWrapper>
      <WelcomeSection>
        <WelcomeContent>
          <WelcomeSmall>Welcome at</WelcomeSmall>
          <WelcomeLarge>HEARTPORT</WelcomeLarge>
        </WelcomeContent>
      </WelcomeSection>
      <HeroSection>
        <HeroMedia>
          {slideShowItems.map((slide, index) => (
            <MediaItem
              key={slide.id}
              active={index === currentSlideIndex}
              position={getSlidePosition(index)}
            >
              {slide.mediaType === 'VIDEO' ? (
                <video
                  ref={el => videoRefs.current[index] = el}
                  playsInline
                  muted
                  autoPlay
                  loop
                  style={{ backgroundColor: '#000' }} // Add this to help see video boundaries
                >
                  <source 
                    src={slide.mediaUrl} 
                    type="video/mp4"
                    onError={(e) => console.error('Error loading video source:', e)}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={slide.mediaUrl} alt="" />
              )}
            </MediaItem>
          ))}
          <LogoOverlay>
            <img src="/images/hero_logo.png" alt="Hero Logo" />
          </LogoOverlay>
        </HeroMedia>
        <HeroContent>
          <BigHeadline>
            <ColoredWord>DEMOCRATIZING SCIENCE</ColoredWord> 
          </BigHeadline>
          <SubHeadline>
            Heartport is your gateway to empowering health for maximizing life.
          </SubHeadline>
          <ScienceCares>
            <ScienceCaresFirstWord>Because</ScienceCaresFirstWord>
            <ScienceCaresRest>SCIENCE CARES</ScienceCaresRest>
          </ScienceCares>
        </HeroContent>
      </HeroSection>
      <SubElementsSection>
        {subElements.map((element, index) => (
          <SubElement
            key={index}
            isConference={element.isConference}
            isClickable={!element.showComingSoon && element.externalUrl}
            onClick={() => handleSubElementClick(element)}
          >
            <SubElementImageWrapper>
              <SubElementImage src={element.url} alt={element.title} />
            </SubElementImageWrapper>
            <SubElementContent>
              <LinkTitle>{element.title}</LinkTitle>
              <LinkDescription>{element.description}</LinkDescription>
            </SubElementContent>
            <ComingSoonBanner show={element.showComingSoon}>
              COMING SOON
            </ComingSoonBanner>
          </SubElement>
        ))}
      </SubElementsSection>
    </MainWrapper>
  );
};


export default SlideShowContent;