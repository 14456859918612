import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/images/logo-navbar.png';
import LanguagePicker from '../components/LanguagePicker';

const HeaderWrapper = styled.header`
  background: transparent;
  padding-left: 2rem;
  padding-right: 4rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  @media (max-width: 768px) {
    padding-right: 2rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled(Link)`
  height: 75px;
  margin-left: 10px;
`;

const LogoImage = styled.img`
  height: 100%;
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <Nav>
        <Logo to="/">
          <LogoImage src={logo} alt="HEARTS Logo" />
        </Logo>
        <LanguagePicker />
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;