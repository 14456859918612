import React from 'react';
import styled from 'styled-components';
import SlideShowContent from '../layouts/SlideShow';
import CommunicationSection from '../components/CommunicationSection';
import FeaturesSection from '../components/FeaturesSection';

const HomeWrapper = styled.div`
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%; // Add this
  max-width: 100%; // Add this to prevent overflow
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const JoinTextWrapper = styled.div`
  margin-left: 0rem;
  margin-right: 0rem;
  margin-top: 2rem;
  width: 100%; // Add this
  display: flex; // Add this
  justify-content: stretch; // Add this
`;

const JoinText = styled.div`
  color: #040a19;
  font-weight: bold;
  width: 100%;
  white-space: nowrap;
  text-align: left; // Ensure left alignment
`;

const Home = () => {
  const textRef = React.useRef(null);

  React.useEffect(() => {
    const fitText = () => {
      const element = textRef.current;
      if (!element || !element.parentElement) {
        console.log('No element or parent found');
        return;
      }

      const containerWidth = element.parentElement.offsetWidth;
      console.log('Container width:', containerWidth);

      // Start with a larger initial size for efficiency
      let fontSize = 1;
      let increment = 1;
      
      element.style.fontSize = fontSize + 'px';
      
      while (element.scrollWidth <= containerWidth && fontSize < 500) {
        fontSize += increment;
        element.style.fontSize = fontSize + 'px';
        
        // Log every few iterations to see progress
        if (fontSize % 10 === 0) {
          console.log('Current font size:', fontSize, 'Text width:', element.scrollWidth);
        }
      }
      
      // Back up to the last good size
      fontSize -= increment;
      element.style.fontSize = fontSize + 'px';
      console.log('Final font size:', fontSize);
    };

    // Initial fit
    fitText();
    
    // Create an observer to watch for container size changes
    const resizeObserver = new ResizeObserver(fitText);
    if (textRef.current?.parentElement) {
      resizeObserver.observe(textRef.current.parentElement);
    }

    // Clean up
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <HomeWrapper>
      <SlideShowContent />
      <JoinTextWrapper>
        <JoinText ref={textRef}>
          Join us in shaping a healthier future with HEARTS
        </JoinText>
      </JoinTextWrapper>
      <FeaturesSection />

      <CommunicationSection />
    </HomeWrapper>
  );
};
export default Home;